import { GraphQLError } from 'graphql/index';
import { IAnyType, IMaybe, IMSTArray } from 'mobx-state-tree';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { GraphqlErrorMessagesEnum, RecaptchaErrors } from '@shared-enum/graphql-error-messages.enum';
import { UserCredentialsStepFormInterface } from '@shared-form/user-credentials-step-form/user-credentials-step-form.interface';
import { MutationUserStartLoginArgs, UserLogin, UserStartLogin, WebPreferredLoginMethod } from '@shared-graphql';
import { RecaptchaExceptionEnum } from '@shared-hook/google-recaptcha/google-recaptcha';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useUserConfirmLogin } from '@shared-hook/login-steps/user-confirm-login.hook';
import { useUserGoogleAuthLogin } from '@shared-hook/login-steps/user-google-auth-login.hook';
import { usePushNotificationLogin } from '@shared-hook/login-steps/user-push-notification-login.hook';
import { useUserStartLogin } from '@shared-hook/login-steps/user-start-login.hook';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { getLocalStorageValue, removeLocalStorageValue } from '@shared-hook/utils/use-local-storage.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { getGraphQLErrorMessages } from '@shared-util/get-graphql-error-code';
import { isExist, isTrue } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';
import { allowRedirectPages } from '@page/auth-pages/login/login-steps/login-steps.options';
import { useFeatureTagsSelector } from '@selector/feature-tags/use-feature-tags.selector';
import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';
import { useUserSelector } from '@selector/user/use-user.selector';

interface LoginStepsInterface {
    phone: string;
}

const initialLoginCredentials = {
    email: '',
    password: '',
    biometry: false,
};

export const useLoginSteps = ({ phone }: LoginStepsInterface) => {
    const navigate = useRouterNavigate();
    const { onOpen } = useContext(InformationModalContext);
    const { setUserPhone, setUserWebPreferredLoginMethod, setUserWebAvailableLoginMethods } = useUserActionsSelector();
    const [
        {
            loading: { isLoading: isFeatureTagsLoading },
        },
    ] = useFeatureTagsSelector();
    const [, { isUserLoading }] = useUserSelector();

    const [isLoginStarted, setLoginStarted] = useState(false);
    const otpErrorText = useLocalizationText(LocalizationEnum.OtpCodeIncorrectError);
    const loginErrorText = useLocalizationText(LocalizationEnum.LoginFormError);

    const loginCredentials = useRef<UserCredentialsStepFormInterface>(initialLoginCredentials);

    const [
        userStartLoginData,
        userStartLoginError,
        isUserStartLoginProcessed,
        userStartLogin,
        recaptchaV2Component,
        rootStartLogin,
    ] = useUserStartLogin();
    const [userConfirmLoginData, userConfirmLoginError, isUserConfirmLoginProcessed, userConfirmLogin] =
        useUserConfirmLogin();
    const [userGoogleAuthData, userGoogleAuthError, isUserGoogleAuthLoginProcessed, userGoogleAuthLogin] =
        useUserGoogleAuthLogin();

    const startLoginErrorMessage = userStartLoginError
        ? getGraphQLErrorMessages(userStartLoginError?.graphQLErrors as GraphQLError[])[0] ??
          userStartLoginError?.message
        : '';

    const [
        userPushNotificationLoginData,
        userPushNotificationLoginError,
        isUserPushNotificationLoginProcessed,
        userPushNotificationLogin,
    ] = usePushNotificationLogin();

    const onSuccess = useCallback(() => {
        const { name, params } = getLocalStorageValue('redirectPage') ?? {};

        removeLocalStorageValue('redirectPage');
        navigate(allowRedirectPages.has(name) ? name : PageEnum.Dashboard, params);
    }, []);

    const [errorMessage, setErrorMessage] = useState<string>();

    const handleUserCredentialsSubmit = useCallback(
        ({ email, password }: UserCredentialsStepFormInterface) => {
            loginCredentials.current.email = email;
            loginCredentials.current.password = password;

            userStartLogin({ email, password, recaptchaToken: '', loginMethod: null });
        },
        [userStartLogin]
    );

    const handleOtpSubmit = useCallback(
        (otp: string) =>
            userConfirmLogin({
                ...loginCredentials.current,
                otp,
            }),
        []
    );

    const handleGoogleAuthSubmit = useCallback(
        (totp: string) =>
            userGoogleAuthLogin({
                email: loginCredentials.current.email,
                password: loginCredentials.current.password,
                totp,
            }),
        []
    );

    const onLoginWithAnotherMethod = useCallback(
        ({ loginMethod, recaptchaToken }: Pick<MutationUserStartLoginArgs, 'loginMethod' | 'recaptchaToken'>) =>
            rootStartLogin({
                email: loginCredentials.current.email,
                password: loginCredentials.current.password,
                recaptchaToken,
                loginMethod,
            }),
        [rootStartLogin, loginCredentials]
    );

    const handlePushNotificationSubmit = useCallback(
        () =>
            userPushNotificationLogin({
                email: loginCredentials.current.email,
                password: loginCredentials.current.password,
            }),
        []
    );

    useEffect(
        () =>
            void (
                isExist((userStartLoginData?.userStartLogin as UserStartLogin)?.phone) &&
                setUserPhone((userStartLoginData?.userStartLogin as UserStartLogin).phone as string)
            ),
        [userStartLoginData]
    );

    useEffect(() => {
        if (isTrue((userStartLoginData?.userStartLogin as UserStartLogin)?.webPreferredLoginMethod)) {
            setUserWebPreferredLoginMethod(
                (userStartLoginData?.userStartLogin as UserStartLogin)
                    .webPreferredLoginMethod as WebPreferredLoginMethod
            );
            setLoginStarted(true);
        }
        if (isTrue((userStartLoginData?.userStartLogin as UserStartLogin)?.webAvailableLoginMethods)) {
            setUserWebAvailableLoginMethods(
                (userStartLoginData?.userStartLogin as UserStartLogin).webAvailableLoginMethods as IMSTArray<
                    IMaybe<IAnyType>
                >
            );
        }
    }, [userStartLoginData]);

    useEffect(
        () => void (isExist((userConfirmLoginData?.userConfirmLogin as UserLogin)?.id) && onSuccess()),
        [userConfirmLoginData]
    );

    useEffect(
        () => void (isExist((userGoogleAuthData?.userGoogleAuthenticatorLogin as UserLogin)?.id) && onSuccess()),
        [userGoogleAuthData]
    );

    useEffect(
        () =>
            void (isExist((userPushNotificationLoginData?.userPushNotificationLogin as UserLogin)?.id) && onSuccess()),
        [userPushNotificationLoginData]
    );

    useEffect(() => {
        if (isExist(userStartLoginError)) {
            if (
                new Set([...RecaptchaErrors, RecaptchaExceptionEnum.ExecuteRecaptchaNotYetAvailable]).has(
                    startLoginErrorMessage as GraphqlErrorMessagesEnum
                )
            ) {
                onOpen(InformationModalEnum.FailureInformationModal, {
                    text: startLoginErrorMessage,
                    timeout: 5000,
                });
            } else {
                setErrorMessage(startLoginErrorMessage ?? loginErrorText);
            }
        } else {
            setErrorMessage(undefined);
        }
    }, [userStartLoginError]);

    useEffect(() => {
        if (isExist(userConfirmLoginError)) {
            setErrorMessage(otpErrorText);
        } else {
            setErrorMessage(undefined);
        }
    }, [userConfirmLoginError]);

    useEffect(() => {
        if (isExist(userGoogleAuthError)) {
            setErrorMessage(isTrue(userGoogleAuthError?.message) ? userGoogleAuthError?.message : otpErrorText);
        } else {
            setErrorMessage(undefined);
        }
    }, [userGoogleAuthError]);

    useEffect(() => {
        if (isExist(userPushNotificationLoginError)) {
            setErrorMessage(otpErrorText);
        } else {
            setErrorMessage(undefined);
        }
    }, [userPushNotificationLoginError]);

    return {
        phone: isLoginStarted ? phone : '',
        errorMessage:
            isUserStartLoginProcessed ||
            isUserGoogleAuthLoginProcessed ||
            isUserConfirmLoginProcessed ||
            isUserPushNotificationLoginProcessed
                ? ''
                : errorMessage,
        handleOtpSubmit,
        handleUserCredentialsSubmit,
        handleGoogleAuthSubmit,
        handlePushNotificationSubmit,
        onLoginWithAnotherMethod,
        setErrorMessage,
        isLoading:
            isUserStartLoginProcessed ||
            isUserGoogleAuthLoginProcessed ||
            isUserConfirmLoginProcessed ||
            isUserPushNotificationLoginProcessed ||
            isFeatureTagsLoading ||
            isUserLoading,
        recaptchaV2Component,
        startLoginErrorMessage,
    };
};
