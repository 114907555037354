import { PaymentType } from '@shared-graphql';

import { PaymentFlowEnum } from '@component/modal/modals/payment/payment.modal.enum';

export const PaymentFlowToPaymentTypeMapping = {
    [PaymentFlowEnum.Internal]: PaymentType.internal,
    [PaymentFlowEnum.CardWithdrawal]: PaymentType.card_withdrawal,
    [PaymentFlowEnum.Local]: PaymentType.local,
    [PaymentFlowEnum.Sepa]: PaymentType.sepa,
    [PaymentFlowEnum.Swift]: PaymentType.swift,
};
