import { useMutation } from '@apollo/client';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';

import { UserCredentialsStepFormInterface } from '@shared-component/forms/user-credentials-step-form/user-credentials-step-form.interface';
import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { UserCreateExternalId, UserLogin } from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useUserLogin } from '@shared-hook/login-steps/user-login.hook';
import { useCurrentNavigation, useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { useSendPhoneCode } from '@shared-hook/phone-code/send-phone-code.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { isExist, isString, isTrue } from '@shared-util/is-data';

import { PageEnum } from '@enum/page.enum';

interface LoginStepsInterface {
    phone: string;
    setUserPhone: OnEventType<string>;
    setUserCompanyRole?: OnEventType<string>;
}

const initialLoginCredentials = {
    email: '',
    password: '',
    biometry: false,
};

export const useAuthPartnersSteps = ({ phone, setUserPhone, setUserCompanyRole }: LoginStepsInterface) => {
    const navigate = useRouterNavigate();
    const {
        searchParams: { token },
    } = useCurrentNavigation();
    const [isSecondStep, setSecondStep] = useState(false);
    const otpErrorText = useLocalizationText(LocalizationEnum.OtpCodeIncorrectError);
    const loginErrorText = useLocalizationText(LocalizationEnum.LoginFormError);
    const { onOpen } = useContext(InformationModalContext);
    const [sendCode, isSendPhoneCodeStatus, isSendPhoneCodeLoading] = useSendPhoneCode();

    const [data, error, isUserLoginProcessed, loadGreeting] = useUserLogin();
    const [
        userCreateExternalId,
        { data: createEternalIdData, loading: isUserCreateExternalIdLoading, error: createEternalIdError },
    ] = useMutation<'userCreateExternalId'>(UserCreateExternalId);

    const [errorMessage, setErrorMessage] = useState<string>();

    const loginCredentials = useRef<UserCredentialsStepFormInterface>(initialLoginCredentials);

    const onResendOtpCode = () => sendCode(phone);

    const handleUserCredentialsSubmit = useCallback(({ email, password }: UserCredentialsStepFormInterface) => {
        loginCredentials.current.email = email;
        loginCredentials.current.password = password;

        loadGreeting({ email, password, otp: null, biometry: null });
    }, []);

    useEffect(() => {
        if (isTrue(phone)) {
            sendCode(phone);
        }
    }, [phone]);

    useEffect(() => {
        if (isTrue(isSendPhoneCodeStatus)) {
            setSecondStep(true);
        }
    }, [isSendPhoneCodeStatus]);

    const handleOtpSubmit = useCallback((otp: string) => {
        userCreateExternalId({
            variables: {
                ...loginCredentials.current,
                token,
                otp,
            },
        });
    }, []);

    useEffect(() => {
        if (isExist((data?.userLogin as UserLogin)?.phone)) {
            setUserPhone((data?.userLogin as UserLogin).phone as string);
        }
        if (isExist((data?.userLogin as UserLogin)?.roleCompany) && setUserCompanyRole) {
            setUserCompanyRole((data?.userLogin as UserLogin).roleCompany as string);
        }
    }, [data]);

    useEffect(() => {
        if (
            Boolean((createEternalIdData as any)?.userCreateExternalId?.externalId) === true &&
            Boolean((createEternalIdData as any)?.userCreateExternalId?.id) === true
        ) {
            navigate(PageEnum.AuthPartnersSuccess);
        }
        if (
            (createEternalIdData as any)?.userCreateExternalId?.externalId === null ||
            (createEternalIdData as any)?.userCreateExternalId?.id === null ||
            createEternalIdError
        ) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: LocalizationEnum.FailureCurrencyExchangeMessage,
            });
        }
    }, [createEternalIdData]);

    useEffect(() => {
        const isPhoneExist = isString(phone);
        const isErrorExist = isExist(error);

        if (isErrorExist) {
            setErrorMessage(isPhoneExist ? otpErrorText : loginErrorText);
        } else {
            setErrorMessage(undefined);
        }
    }, [error]);

    return {
        phone: isSecondStep ? phone : '',
        errorMessage: isUserLoginProcessed ? '' : errorMessage,
        handleOtpSubmit,
        handleUserCredentialsSubmit,
        onResendOtpCode,
        isLoading: isUserLoginProcessed || isSendPhoneCodeLoading || isUserCreateExternalIdLoading,
    };
};
