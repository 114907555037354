import { useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql/index';
import { useCallback, useContext, useEffect } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { SendPhoneCode } from '@shared-graphql';
import { useGoogleRecaptcha } from '@shared-hook/google-recaptcha/google-recaptcha';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { getGraphQLErrorMessages } from '@shared-util/get-graphql-error-code';
import { isExist } from '@shared-util/is-data';

export const useSendPhoneCode = (): [OnEventType<string>, boolean, boolean] => {
    const [sendPhoneCode, { data, loading: isLoading, error }] = useMutation(SendPhoneCode);
    const { onOpen } = useContext(InformationModalContext);

    const handleSendPhoneCode = useCallback(
        (phone: string, token: string) => sendPhoneCode({ variables: { phone, token } }),
        []
    );
    const [handleReCaptchaVerify] = useGoogleRecaptcha(handleSendPhoneCode);

    useEffect(() => {
        if (isExist(error)) {
            onOpen(InformationModalEnum.FailureInformationModal, {
                text:
                    getGraphQLErrorMessages(error?.graphQLErrors as GraphQLError[])[0] ??
                    error?.message ??
                    LocalizationEnum.FailureCurrencyExchangeMessage,
                timeout: 5000,
            });
        }
    }, [error]);

    return [handleReCaptchaVerify, data?.sendPhoneCode.status ?? false, isLoading];
};
