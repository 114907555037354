import styled from 'styled-components';

import ChevronSmallDown from '@shared-asset/vector/chevron-small-down.svg?react';
import { ButtonTransparentStyle } from '@shared-atom/button/button.style';
import { ExternalLinkStyles } from '@shared-atom/external-link/external-link.styles';
import { Link } from '@shared-atom/link/link';
import { Typography } from '@shared-atom/typography/typography';
import { Neutral100, Neutral200, Neutral50, Neutral700, Neutral900, Primary500 } from '@shared-style/colors';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

import { AlignContainer } from '@page/main-pages/main-page-common/main-page-common.styles';

interface WrapperProps {
    isDark: boolean;
    shouldShowHeaderNotification: boolean;
}

export const Wrapper = styled.header<WrapperProps>`
    position: fixed;
    z-index: 99;
    top: 0;
    width: 100%;
    height: ${({ shouldShowHeaderNotification }) => (shouldShowHeaderNotification ? 'calc(72px + 32px)' : '72px')};
    left: 0;
    right: 0;
    background-color: ${({ isDark }) => (isDark ? Neutral900 : Neutral50)};
    border-bottom: 1px solid ${({ isDark }) => (isDark ? Neutral700 : Neutral200)};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        ${AlignContainer} {
            height: ${({ shouldShowHeaderNotification }) =>
                shouldShowHeaderNotification ? 'calc(100% - 32px) !important' : '100%'};
        }
    }
`;

interface HeaderSelectProps {
    isOpen: boolean;
    isDarkMode?: boolean;
    isMobile: boolean;
}
interface HeaderSelectOptionProps {
    isMobile: boolean;
}
export const HeaderSelectArrow = styled(ChevronSmallDown)`
    transition: all 0.2s ease-out;
    path {
        transition: all 0.2s ease-out;
    }
`;
export const HeaderSelectArrowWrapper = styled.div<HeaderSelectProps>`
    ${Flex.HorizontalCenter.VerticalCenter.Style};
    ${HeaderSelectArrow} {
        transform: rotate(${({ isOpen }) => (isOpen ? '180deg' : '0')});
        margin-left: 5px;
        ${({ isMobile, isOpen, isDarkMode = false }) => {
            const closeColor = isDarkMode ? Neutral50 : Neutral900;
            return isMobile
                ? `
            width: 14px;
            height: 10px;
            stroke: ${Neutral900};
            path{
                stroke: ${Neutral900};
            }
        `
                : `
            width: 10px;
            height: 6px;
            stroke: ${isOpen ? Primary500 : closeColor};
            path {
                stroke: ${isOpen ? Primary500 : closeColor};
            }  
        `;
        }}
    }
`;
export const HeaderSelectWrapper = styled.div<HeaderSelectProps>`
    padding: 0 30px;
    cursor: pointer;
    position: relative;
    height: 72px;
    transition: all 0.2s ease-out;
    color: ${({ isOpen, isDarkMode = false }) => {
        const closeColor = isDarkMode ? Neutral50 : Neutral900;
        return isOpen ? Primary500 : closeColor;
    }};
    ${Flex.VerticalCenter.Style}
    ${Typography.Paragraph14.Medium.Style}
    ${({ isMobile, isOpen }) =>
        isMobile &&
        `
        height: unset;
        &:first-child{
            margin-top: auto;
        }
        padding: 0;
        margin: ${isOpen ? '20px 16px' : '16px 16px 32px 16px'};
        ${Flex.FlexStart.Column.Style}
    `}
    &:hover {
        color: ${Primary500};
        ${HeaderSelectArrow} {
            stroke: ${Primary500};
            path {
                stroke: ${Primary500};
            }
        }
    }
`;
export const SelectedItem = styled.div<HeaderSelectOptionProps>`
    display: block;
    ${Typography.Paragraph14.Medium.Center.Style}
    ${({ isMobile }) =>
        isMobile &&
        `
        ${Typography.H4Mob.Neutral900.SemiBold.Center.Style};
    `}
`;
export const SelectedItemWrapper = styled.div<HeaderSelectOptionProps>`
    width: 100%;
    ${Flex.VerticalCenter.SpaceBetween.Style};
    ${({ isMobile }) =>
        isMobile &&
        `
        ${Typography.H4Mob.Neutral900.SemiBold.Style};
        &:hover{
            ${SelectedItem}{
                ${Typography.H4Mob.Primary500.SemiBold.Style};
            }
        }
    `}
`;
export const HeaderSelectOptionsList = styled.div<HeaderSelectProps>`
    ${({ isMobile, isOpen }) =>
        isMobile
            ? `
         position: relative; 
         width: 100%;
         display: ${isOpen ? 'block' : 'none'};
         padding-top: 12px;
    `
            : `
        display: ${isOpen ? 'block' : 'none'};
        position: absolute;
        bottom: 0;
        left: 0;
        width: 260px;
        box-shadow: 0 20px 40px 20px rgba(16, 24, 40, 0.11);
        border-radius: 16px;
        overflow: hidden;
        transform: translateY(104%);
    `}
    ${ExternalLinkStyles} {
        text-decoration: none;
    }
`;
export const HeaderSelectOption = styled(ButtonTransparentStyle)<HeaderSelectOptionProps>`
    width: 100%;
    height: 52px;
    padding-left: 12px;
    border-bottom: 1px solid ${Neutral200};
    background-color: ${Neutral50};
    ${Flex.JustifyLeft.VerticalCenter.Row.Style};
    ${Typography.Paragraph14.SemiBold.Neutral700.Style};

    :last-child:not(:first-child) {
        border-bottom: none;
    }
    :hover {
        background-color: ${Neutral100};
    }
    ${({ isMobile }) =>
        isMobile &&
        `
        padding-left: 0;
        height: 60px;
        ${Typography.Paragraph16.SemiBold.Neutral700.Style}
        :last-child {
           border-bottom: 1px solid ${Neutral200};
        }
        :hover {
            background-color: transparent;
        }
    `}
`;
export const SelectItemIcon = styled.div`
    margin-right: 10px;
    width: 20px;
    height: 20px;
    ${Flex.HorizontalCenter.VerticalCenter.Style}
    svg {
        width: 20px;
    }
`;
export const SelectItemTitle = styled.div`
    ${Typography.Paragraph14.SemiBold.Neutral900.Style}
`;
export const PricingNotification = styled(Link)`
    height: 32px;
    width: 100%;
    background-color: ${Primary500};
    ${Flex.VerticalCenter.HorizontalCenter.Style};
    ${Typography.Paragraph14.SemiBold.Neutral50.Style}
`;
