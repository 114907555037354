import { useCallback, useEffect, useMemo, useState } from 'react';

import { Account } from '@shared-graphql';
import { isTrue } from '@shared-util/is-data';

import { usePaymentUpdate } from '@component/modal/modals/payment/payment-form/payment-update-hook/payment-update.hook';
import { PaymentToSendsFormInitial } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.initial';
import {
    PaymentToSendsFormInterface,
    PaymentToSendsFormProps,
} from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.interface';
import { mapPaymentDataToInitialValues } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.utils';

export const usePaymentToSendsForm = ({
    data,
    accounts,
    selectedAccountId,
    clearPaymentFlow,
    isSignMode,
}: Omit<PaymentToSendsFormProps, 'setModalTitle'>) => {
    const [activeStep, setActiveStep] = useState(0);
    const [initialValues, setInitialValues] = useState(PaymentToSendsFormInitial);
    const onPrevStep = () => setActiveStep(prev => prev - 1);
    const onNextStep = () => setActiveStep(prev => prev + 1);

    const selectedAccount = useMemo(
        () => accounts.find(account => account.accountId === selectedAccountId),
        [selectedAccountId, accounts]
    ) as Account;

    useEffect(() => {
        setInitialValues(mapPaymentDataToInitialValues(data as PaymentToSendsFormInterface, selectedAccount));
    }, [data]);

    const onBackBtnClick = useCallback(
        () => void (isTrue(activeStep === 0) ? clearPaymentFlow(null) : setActiveStep(activeStep - 1)),
        [clearPaymentFlow]
    );

    const [isPaymentError, setPaymentError] = useState(false);

    const {
        paymentUpdateStep,
        signPaymentError,
        setSignPaymentError,
        createPayment,
        isLoadingPayment: isCreatePaymentLoading,
        onOtpSubmit,
        onPaymentSign,
        onSendOtpCode,
    } = usePaymentUpdate(initialValues.id, setPaymentError, isSignMode);

    const onSubmit = ({ attachedFiles, emailOrIban, ...values }: PaymentToSendsFormInterface) => {
        createPayment({
            variables: {
                payment: {
                    ...values,
                    documentIds: attachedFiles?.map(file => file.key),
                },
            },
        }).catch(() => setPaymentError(true));
    };

    return {
        activeStep,
        initialValues,
        isCreatePaymentLoading,
        isPaymentError,
        onBackBtnClick,
        onNextStep,
        onOtpSubmit,
        onPaymentSign,
        onPrevStep,
        onSendOtpCode,
        onSubmit,
        paymentUpdateStep,
        selectedAccount,
        setSignPaymentError,
        signPaymentError,
    };
};
