import { useLazyQuery } from '@apollo/client';
import { observer } from 'mobx-react-lite';
import { useCallback, useContext, useEffect, useState } from 'react';

import { Translate } from '@shared-atom/translate/translate';
import { AnimationLoading } from '@shared-component/animation/animation-loading/animation-loading';
import { ModelContext } from '@shared-component/modal/modal.context';
import { GetPayment, Payment, Query } from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isFalse, isString, isTrue } from '@shared-util/is-data';

import { ModalContentWrapper, ModalHeader, ModalTitle, ModalWrapper } from '@component/modal/client-modal.styles';
import { PaymentFlowSelector } from '@component/modal/modals/payment/payment-flow-selector/payment-flow-selector';
import { PaymentForm } from '@component/modal/modals/payment/payment-form/payment-form';
import { PaymentToCardForm } from '@component/modal/modals/payment/payment-to-card-form/payment-to-card-form';
import { PaymentToSendsForm } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form';
import { PaymentToSendsFormInterface } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.interface';
import {
    getPaymentMap,
    getPaymentToCardMap,
    initialComponentProps,
} from '@component/modal/modals/payment/payment.modal-options';
import { PaymentFormWrapper, PaymentLoading } from '@component/modal/modals/payment/payment.modal-style';
import { PaymentFlowEnum } from '@component/modal/modals/payment/payment.modal.enum';
import { useAvailablePaymentAccounts } from '@hook/available-payment-accounts/available-payment-accounts';
import { useAccountActionsSelector } from '@selector/account/use-account.actions-selector';

export const PaymentModal = observer(() => {
    const {
        props: { componentProps },
        setOnBack,
        onClose,
    } = useContext(ModelContext);
    const { id: paymentId, isSignMode, isRepeatMode } = { ...initialComponentProps, ...componentProps };
    const ccAccounts = useAvailablePaymentAccounts();
    const { loadClientAccounts } = useAccountActionsSelector();
    const [loadPayment, { data, loading: isPaymentLoading }] = useLazyQuery<Pick<Query, 'getPayment'>>(GetPayment);
    const [currentPaymentFlow, setCurrentPaymentFlow] = useState<PaymentFlowEnum | null>(null);
    const [selectedAccount, setSelectedAccount] = useState(ccAccounts[0]);
    const [modalTitle, setModalTitle] = useState<LocalizationEnum>(LocalizationEnum.MakeANewTransfer);

    useEffect(() => {
        if (currentPaymentFlow === PaymentFlowEnum.Swift) {
            setModalTitle(LocalizationEnum.CommonInternationalPayment);
        }
        if (currentPaymentFlow === PaymentFlowEnum.Sepa) {
            setModalTitle(LocalizationEnum.PaymentWithinSepaZone);
        }
        if (currentPaymentFlow === PaymentFlowEnum.Local) {
            setModalTitle(LocalizationEnum.CommonUkLocalPayment);
        }
        if ([PaymentFlowEnum.Internal, PaymentFlowEnum.CardWithdrawal].some(type => type === currentPaymentFlow)) {
            setModalTitle(LocalizationEnum.PaymentFormTitle);
        }
        setModalTitle(LocalizationEnum.MakeANewTransfer);
    }, [currentPaymentFlow]);

    useEffect(() => {
        if (isString(paymentId)) {
            loadPayment({ variables: { paymentId } });
        }
    }, [paymentId]);

    useEffect(() => {
        loadClientAccounts();
        setOnBack(() => onClose);
    }, []);

    const paymentData =
        data?.getPayment !== undefined &&
        isTrue(currentPaymentFlow && currentPaymentFlow !== PaymentFlowEnum.CardWithdrawal)
            ? getPaymentMap(data?.getPayment as Payment, ccAccounts, isRepeatMode)
            : undefined;
    const paymentToCardData =
        data?.getPayment !== undefined && isTrue(currentPaymentFlow === PaymentFlowEnum.CardWithdrawal)
            ? getPaymentToCardMap(data?.getPayment as Payment, ccAccounts, isRepeatMode)
            : undefined;

    useEffect(() => {
        if (data?.getPayment?.type) {
            const account =
                ccAccounts.find(({ currency }) => currency.code === data?.getPayment?.currency.code) ?? ccAccounts[0];
            setCurrentPaymentFlow(data?.getPayment?.type as unknown as PaymentFlowEnum);
            setSelectedAccount(account);
        }
    }, [data]);

    const clearPaymentFlow = useCallback(() => setCurrentPaymentFlow(null), []);

    return (
        <ModalWrapper>
            {isPaymentLoading ? (
                <PaymentLoading>
                    <AnimationLoading />
                </PaymentLoading>
            ) : (
                <>
                    <ModalHeader>
                        <ModalTitle>
                            <Translate langKey={modalTitle} />
                        </ModalTitle>
                    </ModalHeader>
                    <ModalContentWrapper>
                        <PaymentFormWrapper>
                            {isFalse(currentPaymentFlow) ? (
                                <PaymentFlowSelector
                                    accounts={ccAccounts}
                                    setCurrentPaymentFlow={setCurrentPaymentFlow}
                                    setSelectedAccount={setSelectedAccount}
                                />
                            ) : isTrue(currentPaymentFlow === PaymentFlowEnum.CardWithdrawal) ? (
                                <PaymentToCardForm
                                    data={paymentToCardData}
                                    accounts={ccAccounts}
                                    isSignMode={isSignMode}
                                    setModalTitle={setModalTitle}
                                />
                            ) : isTrue(currentPaymentFlow === PaymentFlowEnum.Internal) ? (
                                <PaymentToSendsForm
                                    data={paymentData as unknown as PaymentToSendsFormInterface}
                                    accounts={ccAccounts}
                                    setModalTitle={setModalTitle}
                                    isSignMode={isSignMode}
                                    selectedAccountId={selectedAccount.accountId}
                                    clearPaymentFlow={clearPaymentFlow}
                                />
                            ) : (
                                <PaymentForm
                                    data={paymentData}
                                    accounts={ccAccounts}
                                    isSignMode={isSignMode}
                                    setModalTitle={setModalTitle}
                                    currentPaymentFlow={currentPaymentFlow as PaymentFlowEnum}
                                    clearPaymentFlow={clearPaymentFlow}
                                    selectedAccountId={selectedAccount.accountId}
                                />
                            )}
                        </PaymentFormWrapper>
                    </ModalContentWrapper>
                </>
            )}
        </ModalWrapper>
    );
});
