import React, { useCallback, useEffect } from 'react';

import ArrowLeft from '@shared-asset/vector/arrow-left.svg?react';
import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { WebPreferredLoginMethod } from '@shared-graphql';
import { useGoogleRecaptcha } from '@shared-hook/google-recaptcha/google-recaptcha';
import { useIsMount } from '@shared-hook/utils/use-is-mount';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isFalse, isTrue } from '@shared-util/is-data';

import { LoginMethodSelectorInterface } from '@page/auth-pages/login/login-steps/login-method-selector/login-method-selector.interface';
import { LoginMethodSelectorWrapper } from '@page/auth-pages/login/login-steps/login-method-selector/login-method-selector.styles';

export const LoginMethodSelector = ({
    webAvailableLoginMethods,
    setLoginSelectorVisible,
    onLoginWithAnotherMethod,
    setManuallyPreferredLoginMethod,
    isLoading,
    startLoginErrorMessage,
}: LoginMethodSelectorInterface) => {
    const isMount = useIsMount();

    const userStartLoginHandler = useCallback(
        (loginMethod: WebPreferredLoginMethod, recaptchaToken: string) => {
            onLoginWithAnotherMethod({ loginMethod, recaptchaToken });
        },
        [onLoginWithAnotherMethod]
    );

    const [handleLogin, recaptchaV2Component] = useGoogleRecaptcha(userStartLoginHandler);

    const onMethodButtonClick = useCallback(
        (method: WebPreferredLoginMethod) => {
            setManuallyPreferredLoginMethod(method);
            handleLogin(method);
        },
        [handleLogin]
    );

    useEffect(() => {
        if (!isMount && isFalse(isLoading) && isFalse(startLoginErrorMessage)) {
            setLoginSelectorVisible(false);
        }
    }, [startLoginErrorMessage, isLoading]);

    const onBackButtonClick = useCallback(() => {
        setLoginSelectorVisible(false);
        setManuallyPreferredLoginMethod(null);
    }, []);

    return (
        <LoginMethodSelectorWrapper>
            <Button
                onClick={onBackButtonClick}
                type={ButtonTypeEnum.GHOST}
                size={ButtonSizeEnum.L}
                title={LocalizationEnum.CommonBack}
                iconBefore={ArrowLeft}
                isDisabled={isLoading}
            />
            {isTrue(webAvailableLoginMethods.includes(WebPreferredLoginMethod.Sms)) && (
                <Button
                    onClick={() => onMethodButtonClick(WebPreferredLoginMethod.Sms)}
                    title={LocalizationEnum.CommonSms}
                    size={ButtonSizeEnum.L}
                    type={ButtonTypeEnum.COLOR_STROKE}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                />
            )}
            {isTrue(webAvailableLoginMethods.includes(WebPreferredLoginMethod.GoogleAuthenticator)) && (
                <Button
                    onClick={() => onMethodButtonClick(WebPreferredLoginMethod.GoogleAuthenticator)}
                    title={LocalizationEnum.CommonGoogleAuthenticator}
                    size={ButtonSizeEnum.L}
                    type={ButtonTypeEnum.COLOR_STROKE}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                />
            )}
            {isTrue(webAvailableLoginMethods.includes(WebPreferredLoginMethod.PushNotification)) && (
                <Button
                    onClick={() => onMethodButtonClick(WebPreferredLoginMethod.PushNotification)}
                    title={LocalizationEnum.ThroughSendsApplication}
                    size={ButtonSizeEnum.L}
                    type={ButtonTypeEnum.COLOR_STROKE}
                    isLoading={isLoading}
                    isDisabled={isLoading}
                />
            )}
            {recaptchaV2Component}
        </LoginMethodSelectorWrapper>
    );
};
