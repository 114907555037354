import { useCallback } from 'react';

import { MutationSendPhoneCodeArgs } from '@shared-graphql';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { OnEventType } from '@shared-type/on-event.type';
import { getPhoneNumber } from '@shared-util/get-phone-number';

import { PageEnum } from '@enum/page.enum';
import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';

interface RegistrationPhoneFromInterface {
    handlePhoneSubmit: OnEventType<MutationSendPhoneCodeArgs>;
    handleBack: () => void;
}

export const useRegistrationPhoneForm = (onStartRegistration: OnEventType<string>): RegistrationPhoneFromInterface => {
    const { setUserData } = useUserActionsSelector();

    const navigate = useRouterNavigate();

    const handlePhoneSubmit = useCallback(
        ({ phone }: MutationSendPhoneCodeArgs) => {
            const phoneNumber = getPhoneNumber(phone);
            setUserData({ phone: phoneNumber });
            onStartRegistration(phone);
        },
        [onStartRegistration]
    );

    const handleBack = useCallback(() => {
        navigate(PageEnum.RegistrationPassword);
    }, []);

    return { handlePhoneSubmit, handleBack };
};
