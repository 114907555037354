import { useField } from 'formik';

import { getAccountIdsOptions } from '@shared-atom/elpaso-kit/select-account/select-account-option/select-account-option.utils';
import { useCurrencyFormat } from '@shared-hook/number-format/currency-format.hook';

import { PaymentToSendsDetailsFormProps } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-details-form/payment-to-sends-details-form.interface';
import { PaymentToSendsFormEnum } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.enum';

export const usePaymentToSendsDetailsForm = ({ accounts, fee: { total, amount } }: PaymentToSendsDetailsFormProps) => {
    const accountOptionsSell = getAccountIdsOptions(accounts, true);
    const [{ value: paymentCurrency }] = useField(PaymentToSendsFormEnum.Currency);
    const totalAmount = useCurrencyFormat(total, paymentCurrency);
    const paymentFee = useCurrencyFormat(amount, paymentCurrency);

    return {
        accountOptionsSell,
        paymentCurrency,
        paymentFee,
        totalAmount,
    };
};
