import { object } from 'yup';

import { fieldRequiredValidation, moreThanZeroValidation } from '@shared-util/validation';

import { PaymentToSendsFormEnum } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.enum';

export const PaymentToSendsFormValidation = object().shape({
    [PaymentToSendsFormEnum.Amount]: moreThanZeroValidation(),
    [PaymentToSendsFormEnum.EmailOrIban]: fieldRequiredValidation(),
});
