import { observer } from 'mobx-react-lite';
import { JSX, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import { PageLoader } from '@shared-component/page-loader/page-loader';

import { MainWrapper } from '@page/main-pages/main-page-common/main-wrapper';

const MainPagesPage = observer(({ children }: { children?: ReactNode | ReactNode[] | JSX.Element }) => (
    <MainWrapper>
        <PageLoader>
            <Outlet />
            {children}
        </PageLoader>
    </MainWrapper>
));

export default MainPagesPage;
