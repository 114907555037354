import { ApolloError, useMutation } from '@apollo/client';
import { useCallback, ReactNode } from 'react';

import { Maybe, Mutation, MutationUserStartLoginArgs, UserStartLogin } from '@shared-graphql';
import { useGoogleRecaptcha } from '@shared-hook/google-recaptcha/google-recaptcha';
import { OnEventType } from '@shared-type/on-event.type';

type UserMutationType = Pick<Mutation, 'userStartLogin'>;

export const useUserStartLogin = (): [
    Maybe<UserMutationType> | undefined,
    ApolloError | undefined,
    boolean,
    OnEventType<MutationUserStartLoginArgs>,
    ReactNode | null,
    OnEventType<MutationUserStartLoginArgs>
] => {
    const [userStartLogin, { data, error, loading: isLoading }] = useMutation<UserMutationType>(UserStartLogin);
    const userStartLoginHandler = useCallback((variables: MutationUserStartLoginArgs, recaptchaToken: string) => {
        userStartLogin({ variables: { ...variables, recaptchaToken } });
    }, []);

    const rootStartLogin = useCallback(
        (variables: MutationUserStartLoginArgs) => userStartLogin({ variables: { ...variables } }),
        []
    );

    const [handleLogin, RecaptchaV2Component] = useGoogleRecaptcha(userStartLoginHandler);
    return [
        data,
        error,
        isLoading,
        handleLogin as OnEventType<MutationUserStartLoginArgs>,
        RecaptchaV2Component,
        rootStartLogin,
    ];
};
