import styled from 'styled-components';

import ArrowLeftIcon from '../../assets/vector/arrow-left.svg?react';
import { DarkGrey } from '../../styles/colors';

export const ArrowLeft = styled(ArrowLeftIcon)`
    fill: ${DarkGrey};
`;

export const ButtonTitleView = styled.span`
    color: ${DarkGrey};
    margin-left: 8px;
`;
