import { Form, Formik } from 'formik';
import React, { ReactNode, useEffect, useState } from 'react';

import ArrowLeft from '@shared-asset/vector/arrow-left.svg?react';
import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { InputPhone } from '@shared-component/input/input-phone/input-phone';
import { FormRow, FormWrapper } from '@shared-form/form-common/form-common.styles';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';

import { UserKeysEnum } from '@model/user-model/user-model.interfaces';
import { FormButtonsWrapper } from '@page/registration/registration-additional-fields/registration-additional-fields.styles';
import { RegistrationPhoneFormInitial } from '@page/registration/registration-phone/registration-phone-form/registration-phone-form.initial';
import {
    RegistrationPhoneFormEnum,
    RegistrationPhoneSchema,
    RegistrationPhoneType,
} from '@page/registration/registration-phone/registration-phone-form/registration-phone-form.options';

interface RegistrationPhoneFromProps {
    onSubmit: OnEventType<RegistrationPhoneType>;
    onBack: () => void;
    isLoading?: boolean;
    phone: string;
    recaptchaV2Component: ReactNode | null;
}

export const RegistrationPhoneForm = ({
    onSubmit,
    isLoading,
    onBack,
    phone,
    recaptchaV2Component,
}: RegistrationPhoneFromProps) => {
    const phoneTitle = useLocalizationText(LocalizationEnum.RegistrationPhoneTitle);
    const [initialValues, setInitialValues] = useState(RegistrationPhoneFormInitial);

    useEffect(() => {
        setInitialValues({
            ...RegistrationPhoneFormInitial,
            [RegistrationPhoneFormEnum.Phone]: phone,
        });
    }, [phone]);

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={RegistrationPhoneSchema}
            validateOnChange={false}
            onSubmit={onSubmit}
            enableReinitialize
        >
            <Form>
                <FormWrapper>
                    <FormRow>
                        <InputPhone name={UserKeysEnum.Phone} title={phoneTitle} />
                    </FormRow>
                </FormWrapper>
                {recaptchaV2Component}
                <FormButtonsWrapper>
                    <Button
                        onClick={onBack}
                        type={ButtonTypeEnum.GHOST}
                        size={ButtonSizeEnum.L}
                        title={LocalizationEnum.CommonBack}
                        iconBefore={ArrowLeft}
                    />
                    <Button
                        title={LocalizationEnum.RegistrationLegalCompanyButtonTitle}
                        size={ButtonSizeEnum.L}
                        type={ButtonTypeEnum.COLOR}
                        isSubmit
                        isLoading={isLoading}
                        isDisabled={isLoading}
                    />
                </FormButtonsWrapper>
            </Form>
        </Formik>
    );
};
