import styled from 'styled-components';

import { Typography } from '@shared-atom/typography/typography';
import { Primary300, Primary500 } from '@shared-style/colors';
import { OtpFormWrapper } from '@shared-style/common';
import { Flex } from '@shared-style/flex';

interface AuthPageButtonWithTimerWrapperProps {
    isDisabled?: boolean;
}

export const AuthPageButtonWrapper = styled.div`
    margin-top: 24px;
    ${Flex.Row.VerticalCenter.HorizontalCenter.Style}
`;

export const AuthPageButtonWithTimerWrapper = styled.div<AuthPageButtonWithTimerWrapperProps>`
    margin: 24px auto 0;
    text-align: center;
    color: ${({ isDisabled = false }) => (isDisabled ? Primary300 : Primary500)};
    button,
    span {
        color: ${({ isDisabled = false }) => (isDisabled ? Primary300 : Primary500)};
    }
`;

export const AuthPageFormWrapper = styled(OtpFormWrapper)`
    margin-top: 24px;
`;

export const AuthPageContentWrapper = styled.div`
    margin: 0 auto;
    width: 41%;
    max-width: 400px;
    padding-top: 55px;
    height: 100%;
    min-width: 360px;
    @media (max-width: 1200px) {
        width: 50%;
    }
    @media (max-width: 768px) {
        width: 85%;
    }
    @media (max-width: 560px) {
        width: 100%;
        min-width: unset;
    }
`;

export const AuthPageFormTitle = styled.h1`
    ${Typography.XXL.Style}
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #090909;
    text-align: center;
`;

export const AuthPageContentDescriptionText = styled.div`
    ${Typography.Fiord.Style}
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
    text-align: center;
`;

export const AuthPageContentDescriptionWrapper = styled.div`
    margin: 0 auto;
    width: 90%;
    margin-top: 16px;
    margin-bottom: 24px;
`;

export const RecaptchaV2Container = styled.div`
    ${Flex.Row.VerticalCenter.HorizontalCenter.Style};
    margin: 16px auto;
    max-width: 100%;
`;
