import {
    applySnapshot,
    getSnapshot,
    IAnyType,
    IArrayType,
    IMaybe,
    IMSTArray,
    Instance,
    IStateTreeNode,
} from 'mobx-state-tree';

import { Maybe, UserType, WebPreferredLoginMethod } from '@shared-graphql';

import { UserModelInstance } from '@model/user-model/user-model';
import { initialUserModel, ModelUserInterface } from '@model/user-model/user-model-initials/user-model.initial';

export const userModelMainActions = (self: Instance<typeof UserModelInstance>) => ({
    setUserLoading: (loading: boolean) => {
        self.loading.isUserLoading = loading;
    },
    setUserProfileLoading: (loading: boolean) => {
        self.loading.isProfileLoading = loading;
    },
    setUserEmail: (email: string) => {
        self.user.email = email;
    },
    setUserPhone: (phone: string) => {
        self.user.phone = phone;
    },
    setUserWebPreferredLoginMethod: (webPreferredLoginMethod: WebPreferredLoginMethod) => {
        self.user.webPreferredLoginMethod = webPreferredLoginMethod;
    },
    setUserWebAvailableLoginMethods: (
        webAvailableLoginMethods: IMSTArray<IMaybe<IAnyType>> & IStateTreeNode<IArrayType<IMaybe<IAnyType>>>
    ) => {
        self.user.webAvailableLoginMethods = webAvailableLoginMethods;
    },
    setUserCompanyRole: (companyRole: string) => {
        self.user.companyRole = companyRole;
    },
    /* setUserPermissions: (companyPermissions: Array<string>) => {
        // self.user.companyPermissions = cast(companyPermissions);
        (self.user as any).companyPermissions = companyPermissions;
    }, */
    setUserData: (user: Partial<ModelUserInterface>) => {
        const currentSnapshot = getSnapshot(self);
        applySnapshot(self, {
            ...currentSnapshot,
            user: {
                ...currentSnapshot.user,
                ...user,
            } as any,
        });
    },
    setUserActivationStatus: (isUserActivated: boolean) => {
        self.user.isUserActivated = isUserActivated;
    },
    clearUserData: () => {
        self.user = initialUserModel as any;
    },
    setUserType: (type: Maybe<UserType>) => {
        self.user.type = type;
    },
    setUserError: (errorMessage: string) => {
        self.error = errorMessage;
    },
});
