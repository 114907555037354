import { Form, Formik } from 'formik';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { CustomInput } from '@shared-atom/elpaso-kit/input/custom-input/custom-input';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { FooterFollowFormEnum } from '@page/main-pages/main-page-common/footer/footer-follow-form/footer-follow-form.enum';
import { useFooterFollowForm } from '@page/main-pages/main-page-common/footer/footer-follow-form/footer-follow-form.hook';
import { footerFollowFormInitial } from '@page/main-pages/main-page-common/footer/footer-follow-form/footer-follow-form.initial';
import { FooterFollowFormWrapper } from '@page/main-pages/main-page-common/footer/footer-follow-form/footer-follow-form.styles';
import { footerFollowFormValidationSchema } from '@page/main-pages/main-page-common/footer/footer-follow-form/footer-follow-form.validation';

export const FooterFollowForm = () => {
    const { onSubmit, isLoading } = useFooterFollowForm();

    return (
        <FooterFollowFormWrapper>
            <Formik
                initialValues={footerFollowFormInitial}
                onSubmit={({ email }, { resetForm }) => onSubmit({ email, resetForm })}
                validateOnChange={false}
                validationSchema={footerFollowFormValidationSchema}
            >
                <Form>
                    <CustomInput
                        label={LocalizationEnum.FollowOurLatestNews}
                        name={FooterFollowFormEnum.Email}
                        placeholder={LocalizationEnum.CommonEmailAddress}
                        isMobile
                    />
                    <Button
                        isSubmit
                        isLoading={isLoading}
                        title={LocalizationEnum.CommonSubscribe}
                        size={ButtonSizeEnum.L}
                        type={ButtonTypeEnum.COLOR}
                    />
                </Form>
            </Formik>
        </FooterFollowFormWrapper>
    );
};
