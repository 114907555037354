import { Translate } from '@shared-atom/translate/translate';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isString } from '@shared-util/is-data';

import { useForgotPassword } from '@hook/user/user-forgot-password.hook';
import {
    AuthPageContentDescriptionText,
    AuthPageContentDescriptionWrapper,
    AuthPageContentWrapper,
    AuthPageFormTitle,
} from '@page/auth-pages/common.styles';

import { ForgotPasswordSentMessage } from './forgot-password-sent-message';
import { ForgotPasswordForm } from './forgot-password.form';

const ForgotPasswordPage = () => {
    const [handleForgotPassword, sentMessage, isLoading, recaptchaV2Component] = useForgotPassword();

    return isString(sentMessage) ? (
        <ForgotPasswordSentMessage />
    ) : (
        <AuthPageContentWrapper>
            <AuthPageFormTitle>
                <Translate langKey={LocalizationEnum.RecoveryPassword} />
            </AuthPageFormTitle>
            <AuthPageContentDescriptionWrapper>
                <AuthPageContentDescriptionText>
                    <Translate langKey={LocalizationEnum.ForgotPasswordSendLink} />
                </AuthPageContentDescriptionText>
            </AuthPageContentDescriptionWrapper>
            <ForgotPasswordForm handleForgotPassword={handleForgotPassword} isLoading={isLoading} />
            {recaptchaV2Component}
        </AuthPageContentWrapper>
    );
};
export default ForgotPasswordPage;
