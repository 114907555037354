import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

import { ButtonLink } from '@shared-atom/button/button-link';
import { Translate } from '@shared-atom/translate/translate';
import { OtpDescriptionText } from '@shared-component/forms/otp-send-form/otp-send-form.styles';
import { InputOtp } from '@shared-component/input/input-otp/input-otp';
import { Timer } from '@shared-component/timer/timer';
import { OtpSendFormFieldsInterface } from '@shared-form/otp-send-form/fields/otp-send-form-fields.interface';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventEmptyType } from '@shared-type/on-event.type';
import { noop } from '@shared-util/noop';

import { AuthPageButtonWithTimerWrapper } from '@page/auth-pages/common.styles';

const TWO_MINUTE = 2 * 60_000;
const OTP_VALUE_LENGTH = 11;

export const OtpSendFormFields = ({
    name,
    error,
    onResendOtpCode,
    onChange = noop,
    phone = '',
}: OtpSendFormFieldsInterface) => {
    const [isResendAvailable, setResendAvailable] = useState(false);

    const [{ value }, { error: otpError }, { setError }] = useField(name);

    const { setErrors, handleSubmit } = useFormikContext();

    const handleTimerComplete = () => setResendAvailable(true);
    const handleOtpResend = () => {
        setResendAvailable(false);
        (onResendOtpCode as OnEventEmptyType)();
    };

    useEffect(
        () =>
            void (
                error !== undefined &&
                otpError !== error &&
                value?.length === OTP_VALUE_LENGTH &&
                setErrors({ [name]: error })
            ),
        [otpError, error, value]
    );

    useEffect(() => {
        if (value?.length === OTP_VALUE_LENGTH) {
            handleSubmit();
        } else {
            setError(undefined);
        }
    }, [value]);

    return (
        <>
            <OtpDescriptionText>
                <Translate langKey={LocalizationEnum.RegistrationOtpDescription} />
                {phone}
            </OtpDescriptionText>
            <InputOtp name={name} onChange={onChange} />
            {isResendAvailable ? (
                <AuthPageButtonWithTimerWrapper>
                    <ButtonLink onClick={handleOtpResend}>
                        <Translate langKey={LocalizationEnum.OtpPhoneFormResendSms} />
                    </ButtonLink>
                </AuthPageButtonWithTimerWrapper>
            ) : (
                <AuthPageButtonWithTimerWrapper isDisabled>
                    <Translate langKey={LocalizationEnum.OtpPhoneFormDescriptionWait} />{' '}
                    <Timer time={TWO_MINUTE} onComplete={handleTimerComplete} />
                </AuthPageButtonWithTimerWrapper>
            )}
        </>
    );
};
