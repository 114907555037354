import { useLazyQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { extractBIC, isSEPACountry } from 'ibantools';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import {
    Account,
    GetBeneficiaryDetailsRequired,
    GetIbanInfo,
    GetPurposeCodes,
    PaymentType,
    PurposeAdditionalField,
    PurposeCode,
    Query,
} from '@shared-graphql';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { omit } from '@shared-util/common';
import { DEBOUNCE_DELAY, GREAT_BRITAIN_COUNTRY_CODE } from '@shared-util/constants';
import { isExist, isFalse, isString, isTrue } from '@shared-util/is-data';

import { AccountCurrencyFormEnum } from '@component/modal/modals/payment/payment-form/payment-form-steps/amount-currency-form/amount-currency-form.enum';
import { PaymentDetailsFormEnum } from '@component/modal/modals/payment/payment-form/payment-form-steps/payment-details-form/payment-details-form.enum';
import { usePaymentFee } from '@component/modal/modals/payment/payment-form/payment-form-steps/payment-fees-hook/payment-fees.hook';
import {
    BENEFICIARY_PREFIX,
    CC_REQUIRED_FIELD_REGEXP,
} from '@component/modal/modals/payment/payment-form/payment-form-steps/payment-form-steps.options';
import { validationSchemaInitial } from '@component/modal/modals/payment/payment-form/payment-form.initial';
import {
    PaymentFormValuesInterface,
    ValidationSchemaTypeInterface,
} from '@component/modal/modals/payment/payment-form/payment-form.interface';

export const usePaymentFormSteps = (
    currentValidationSchema: ValidationSchemaTypeInterface,
    onUpdateValidationSchema: OnEventType<ValidationSchemaTypeInterface>,
    selectedAccount: Account,
    restrictedCountries: string[]
) => {
    const { onOpen } = useContext(InformationModalContext);
    const [shouldNextStepDisable, setShouldNextStepDisable] = useState(false);
    const sepaIncorrectCountryErrorText = useLocalizationText(
        LocalizationEnum.TheCountryOfTheRecipientsBankIsNotInSepaZoneWithoutLink
    );
    const swiftIncorrectCountryErrorText = useLocalizationText(
        LocalizationEnum.TheCountryOfTheRecipientsBankIsNotPermitted
    );
    const localIncorrectCountryErrorText = useLocalizationText(
        LocalizationEnum.WrongIbanTheCountryOfTheRecipientsBankMustBeGreatBritain
    );

    const [loadBeneficiaryDetailsRequired, { data, loading: isBeneficiaryDetailsLoading }] =
        useLazyQuery<Pick<Query, 'getBeneficiaryDetailsRequired'>>(GetBeneficiaryDetailsRequired);

    const [loadPurposeCodes, { data: purposeCodesData, loading: isPurposeCodesLoading }] =
        useLazyQuery<Pick<Query, 'getPurposeCodes'>>(GetPurposeCodes);

    const [getIbanInfo, { data: ibanInfoData, loading: isIbanInfoLoading }] =
        useLazyQuery<Pick<Query, 'getIbanInfo'>>(GetIbanInfo);

    const [purposeCodes, setPurposeCodes] = useState<PurposeCode[]>([]);
    const { values, setFieldValue, setErrors, setFieldError, validateField, errors } =
        useFormikContext<PaymentFormValuesInterface>();

    const currency = selectedAccount?.currency?.code;

    const {
        amount = 0,
        type,
        purpose,
        beneficiary: { iban, bankCountry, bankName, bicSwift, beneficiaryEntityType: entityType },
    } = values;

    const { countryCode } = extractBIC(bicSwift ?? '');

    const { fee, isLoadingFee } = usePaymentFee({
        ...omit(values, 'attachedFiles'),
        currency,
        shouldCheckPurpose: purposeCodes.length > 0,
    });

    const isCountryPermitted = useMemo(
        () => countryCode !== '' && isFalse(restrictedCountries.includes(countryCode ?? '')),
        [countryCode]
    );

    const additionalFields = useMemo(
        () => purposeCodes.find(({ code }) => code === purpose)?.additionalFields ?? [],
        [purpose, purposeCodes]
    );

    const additionalFieldsValidation = useMemo(() => {
        const initialAdditionalFieldsValidation = {
            beneficiaryInvoiceNumber: null,
            beneficiaryInvoiceDate: null,
            beneficiaryCharityNumber: null,
        };

        (additionalFields as PurposeAdditionalField[])?.forEach(({ name, length }) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            initialAdditionalFieldsValidation[
                `${BENEFICIARY_PREFIX}${name.charAt(0).toUpperCase() + name.slice(1)}`
            ] = `/^.{${length + 1},}|^$/`;
        });

        return initialAdditionalFieldsValidation;
    }, [additionalFields]);

    const validationSchema = useMemo(
        () => ({
            [PaymentDetailsFormEnum.Reason]: CC_REQUIRED_FIELD_REGEXP,
            ...data?.getBeneficiaryDetailsRequired?.find(
                ({ beneficiaryEntityType }) => beneficiaryEntityType === entityType
            ),
            ...(purposeCodes.length > 0 && { purpose: CC_REQUIRED_FIELD_REGEXP }),
        }),
        [entityType, data?.getBeneficiaryDetailsRequired, purposeCodes, additionalFields]
    ) as unknown as ValidationSchemaTypeInterface;

    const debouncedLoadBeneficiaryDetailsRequired = useDebouncedCallback(vars => {
        loadBeneficiaryDetailsRequired(vars);
    }, DEBOUNCE_DELAY);

    const debouncedGetIbanInfo = useDebouncedCallback(vars => {
        getIbanInfo(vars);
    }, DEBOUNCE_DELAY);

    const debouncedLoadPurposeCodes = useDebouncedCallback(vars => {
        setPurposeCodes([]);
        loadPurposeCodes(vars);
    }, DEBOUNCE_DELAY);

    useEffect(() => {
        if (isTrue(amount) && isTrue(currency) && isTrue(type) && selectedAccount !== undefined) {
            debouncedLoadBeneficiaryDetailsRequired({
                variables: {
                    currency,
                    paymentType: type,
                },
            });
        }
    }, [amount, currency, type]);

    useEffect(() => {
        setFieldValue(AccountCurrencyFormEnum.BankCountry, countryCode);
        setErrors(omit(errors, AccountCurrencyFormEnum.BicSwift));
        setShouldNextStepDisable(false);

        if (isString(countryCode)) {
            validateField(AccountCurrencyFormEnum.BankCountry);
            if (type === PaymentType.sepa && isString(countryCode) && !isSEPACountry(countryCode as string)) {
                onOpen(InformationModalEnum.FailureInformationModal, {
                    text: LocalizationEnum.TheCountryOfTheRecipientsBankIsNotInSepaZone,
                    timeout: 6000,
                });
                setFieldError(AccountCurrencyFormEnum.BicSwift, sepaIncorrectCountryErrorText);
                setShouldNextStepDisable(true);
            }

            if (type === PaymentType.swift && !isCountryPermitted) {
                onOpen(InformationModalEnum.FailureInformationModal, {
                    text: LocalizationEnum.TheCountryOfTheRecipientsBankIsNotPermitted,
                    timeout: 4000,
                });
                setFieldError(AccountCurrencyFormEnum.BicSwift, swiftIncorrectCountryErrorText);
                setShouldNextStepDisable(true);
            }
            if (type === PaymentType.local && countryCode !== GREAT_BRITAIN_COUNTRY_CODE) {
                onOpen(InformationModalEnum.FailureInformationModal, {
                    text: LocalizationEnum.WrongIbanTheCountryOfTheRecipientsBankMustBeGreatBritain,
                    timeout: 4000,
                });
                setFieldError(AccountCurrencyFormEnum.BicSwift, localIncorrectCountryErrorText);
                setShouldNextStepDisable(true);
            }

            debouncedLoadPurposeCodes({
                variables: {
                    countryCode,
                },
            });
        }
    }, [countryCode]);

    useEffect(() => {
        if (isString(iban)) {
            debouncedGetIbanInfo({
                variables: {
                    iban,
                },
            });
        }
    }, [iban]);

    useEffect(() => {
        if (isString(bankName)) {
            validateField(AccountCurrencyFormEnum.BankName);
        }
    }, [bankName]);

    useEffect(() => {
        if (isString(bankCountry)) {
            validateField(AccountCurrencyFormEnum.BankCountry);
        }
    }, [bankCountry]);

    useEffect(() => {
        if (isTrue(ibanInfoData?.getIbanInfo.bankName)) {
            setFieldValue(
                AccountCurrencyFormEnum.BankName,
                isTrue(ibanInfoData?.getIbanInfo.valid) ? ibanInfoData?.getIbanInfo.bankName : ''
            );
        }
    }, [ibanInfoData]);

    useEffect(
        () => void (validationSchemaInitial !== validationSchema && onUpdateValidationSchema(validationSchema)),
        [validationSchema]
    );

    useEffect(() => {
        if (isExist(purposeCodesData?.getPurposeCodes)) {
            setPurposeCodes(purposeCodesData?.getPurposeCodes as PurposeCode[]);
        }
    }, [purposeCodesData]);

    useEffect(() => {
        const updatedValidationSchema = {
            ...currentValidationSchema,
            ...(purposeCodes.length > 0 && {
                purpose: CC_REQUIRED_FIELD_REGEXP,
                ...additionalFieldsValidation,
            }),
        };
        onUpdateValidationSchema(updatedValidationSchema);
    }, [purposeCodes, additionalFieldsValidation]);

    const isNextStepDisable = useMemo(
        () => !isCountryPermitted || shouldNextStepDisable,
        [isCountryPermitted, shouldNextStepDisable]
    );

    return {
        fee,
        purposeCodes,
        isNextStepDisable,
        isAdditionalDataLoading:
            isPurposeCodesLoading || isBeneficiaryDetailsLoading || isLoadingFee || isIbanInfoLoading,
    };
};
