import styled from 'styled-components';

import { ElpasoNotification } from '@shared-atom/elpaso-kit/notification/style';
import { getAnimationStyle } from '@shared-style/animation';
import { Flex } from '@shared-style/flex';
import { MAX_MOBILE_SIZE } from '@shared-style/media-sizes';

interface ClientInformationModalProps {
    isVisible: boolean;
    isDashboardModal?: boolean;
}

export const ClientInformationModalWrapper = styled.div<ClientInformationModalProps>`
    position: fixed;
    overflow: hidden;
    border-radius: 8px;
    pointer-events: all;
    top: 24px;
    right: ${({ isDashboardModal = false }) => (isDashboardModal ? 'unset' : '16px')};
    left: ${({ isDashboardModal = false }) => (isDashboardModal ? '276px;' : 'unset')};
    z-index: 101;
    ${Flex.VerticalCenter.Style};
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
    box-shadow: 0 20px 40px 0 rgba(16, 24, 40, 0.31);
    ${getAnimationStyle(['opacity'])};
    @media (max-width: ${MAX_MOBILE_SIZE}px) {
        width: calc(100% - 40px);
        right: unset;
        top: ${({ isDashboardModal = false }) => (isDashboardModal ? '100px;' : '16px')};
        left: 20px;
        ${ElpasoNotification} {
            width: 100%;
        }
    }
`;
