import { useLazyQuery } from '@apollo/client';
import { useFormikContext } from 'formik';
import { GraphQLError } from 'graphql/index';
import { useEffect, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import {
    GetFee,
    GetInternalBeneficiaryDetails,
    InternalBeneficiaryDetails,
    PaymentEntityType,
    PaymentFee,
    Query,
} from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { omit } from '@shared-util/common';
import { DEBOUNCE_DELAY } from '@shared-util/constants';
import { getGraphQLErrorMessages } from '@shared-util/get-graphql-error-code';
import { isExist, isString, isTrue } from '@shared-util/is-data';

import { PaymentToSendsFormEnum } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.enum';
import { PaymentToSendsFormInterface } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.interface';
import { PaymentToSendsStepFormProps } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-step-form/payment-to-sends-step-form.interface';
import { useActiveAccounts } from '@hook/active-accounts/active-accounts.hook';

export const usePaymentToSendsStepForm = ({ activeStep }: Pick<PaymentToSendsStepFormProps, 'activeStep'>) => {
    const canCreate = useActiveAccounts();

    const isFirstStepActive = activeStep === 0;
    const isLastStepActive = activeStep === 1;

    const { values, setFieldValue, setErrors, setFieldError } = useFormikContext<PaymentToSendsFormInterface>();

    const { amount, currency, emailOrIban } = useMemo(() => values, [values]);

    const [
        getInternalBeneficiaryDetails,
        {
            data: getInternalBeneficiaryDetailsData,
            loading: isGetInternalBeneficiaryDetailsLoading,
            error: getInternalBeneficiaryDetailsError,
        },
    ] = useLazyQuery<Pick<Query, 'getInternalBeneficiaryDetails'>>(GetInternalBeneficiaryDetails);

    const [getFee, { data: getFeeData, loading: isGetFeeLoading, error: getFeeError }] =
        useLazyQuery<Pick<Query, 'getFee'>>(GetFee);

    const debouncedLoadBeneficiaryDetailsRequired = useDebouncedCallback(vars => {
        getInternalBeneficiaryDetails(vars);
    }, DEBOUNCE_DELAY);

    const debouncedLoadPaymentFee = useDebouncedCallback(vars => {
        getFee(vars);
    }, DEBOUNCE_DELAY);

    useEffect(() => {
        if (isTrue(emailOrIban)) {
            debouncedLoadBeneficiaryDetailsRequired({
                variables: {
                    currency,
                    emailOrIban: emailOrIban.trim(),
                },
            });
        }
    }, [emailOrIban]);

    const beneficiaryDetailsData = useMemo(
        () => (getInternalBeneficiaryDetailsData?.getInternalBeneficiaryDetails as InternalBeneficiaryDetails) ?? {},
        [getInternalBeneficiaryDetailsData]
    );

    const isReadyToProcessing = useMemo(
        () =>
            [currency, emailOrIban, beneficiaryDetailsData.type, beneficiaryDetailsData?.iban].every(value =>
                isString(value)
            ) && amount > 0,
        [currency, amount, emailOrIban, beneficiaryDetailsData]
    );

    const isNextBtnDisabled = useMemo(
        () => !canCreate || isTrue(getInternalBeneficiaryDetailsError) || isTrue(getFeeError),
        [canCreate, getInternalBeneficiaryDetailsError, getFeeError]
    );
    const fee = useMemo(() => (getFeeData?.getFee as PaymentFee) ?? { total: 0, amount: 0 }, [getFeeData]);

    useEffect(() => {
        if (isReadyToProcessing) {
            const beneficiary = {
                iban: beneficiaryDetailsData?.iban,
                beneficiaryEntityType: beneficiaryDetailsData?.type,
                bicSwift: beneficiaryDetailsData?.bicSwift,
                ...(beneficiaryDetailsData?.type === PaymentEntityType.individual && {
                    beneficiaryFirstName: beneficiaryDetailsData?.name,
                    beneficiaryLastName: '',
                }),
                ...(beneficiaryDetailsData?.type === PaymentEntityType.company && {
                    beneficiaryCompanyName: beneficiaryDetailsData?.name,
                }),
            };
            setFieldValue(PaymentToSendsFormEnum.Beneficiary, beneficiary);
            debouncedLoadPaymentFee({
                variables: {
                    payment: {
                        ...omit(values, 'attachedFiles', 'emailOrIban'),
                        beneficiary,
                    },
                },
            });
        }
    }, [amount, isReadyToProcessing, beneficiaryDetailsData]);

    useEffect(() => {
        if (isExist(getInternalBeneficiaryDetailsError)) {
            setErrors({
                [PaymentToSendsFormEnum.EmailOrIban]:
                    getGraphQLErrorMessages(getInternalBeneficiaryDetailsError?.graphQLErrors as GraphQLError[])[0] ??
                    getInternalBeneficiaryDetailsError?.message ??
                    LocalizationEnum.FailureCurrencyExchangeMessage,
            });
        }
        if (isExist(getFeeError)) {
            setFieldError(
                PaymentToSendsFormEnum.Amount,
                getGraphQLErrorMessages(getFeeError?.graphQLErrors as GraphQLError[])[0] ??
                    getFeeError?.message ??
                    LocalizationEnum.FailureCurrencyExchangeMessage
            );
        }
    }, [getInternalBeneficiaryDetailsError, getFeeError]);

    useEffect(() => {
        if (isExist(getInternalBeneficiaryDetailsData?.getInternalBeneficiaryDetails)) {
            setErrors({});
        }
    }, [getInternalBeneficiaryDetailsData]);

    return {
        canCreate,
        fee,
        isFirstStepActive,
        isGetFeeLoading,
        isGetInternalBeneficiaryDetailsLoading,
        isLastStepActive,
        isNextBtnDisabled,
    };
};
