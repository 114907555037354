import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import ReCAPTCHA from 'react-google-recaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { GraphqlErrorMessagesEnum, RecaptchaErrors } from '@shared-enum/graphql-error-messages.enum';
import { isFalse } from '@shared-util/is-data';

import { RecaptchaV2Container } from '@page/auth-pages/common.styles';
import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';
import { useUserSelector } from '@selector/user/use-user.selector';

export enum RecaptchaExceptionEnum {
    ExecuteRecaptchaNotYetAvailable = 'Execute recaptcha not yet available',
    PleaseVerifyTheReCAPTCHA = 'Please verify the reCAPTCHA',
}

export const useGoogleRecaptcha = (callback: any): [any, ReactNode | null] => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const recaptcha = useRef(null);
    const [isRecaptchaV2Flow, setRecaptchaV2Flow] = useState(false);
    const [, , userError] = useUserSelector();
    const { setUserError } = useUserActionsSelector();

    const RecaptchaV2Component = useMemo(() => {
        if (isRecaptchaV2Flow) {
            return (
                <RecaptchaV2Container>
                    <ReCAPTCHA ref={recaptcha} sitekey={import.meta.env.VITE_RECAPTCHA_V2_KEY} />
                </RecaptchaV2Container>
            );
        }
        return null;
    }, [isRecaptchaV2Flow, recaptcha]);

    useEffect(
        () => () => {
            setUserError('');
            setRecaptchaV2Flow(false);
        },
        []
    );

    useEffect(() => {
        if (
            new Set([...RecaptchaErrors, RecaptchaExceptionEnum.ExecuteRecaptchaNotYetAvailable]).has(
                userError as GraphqlErrorMessagesEnum
            )
        ) {
            setRecaptchaV2Flow(true);
        }
    }, [userError]);

    const handler = useCallback(
        async (props: any) => {
            if (isRecaptchaV2Flow) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                const captchaValue = recaptcha?.current?.getValue();
                if (isFalse(captchaValue)) {
                    setUserError(RecaptchaExceptionEnum.PleaseVerifyTheReCAPTCHA);
                } else {
                    setRecaptchaV2Flow(false);
                    setUserError('');
                    callback(props, `RECAPTCHAV2|${captchaValue}`);
                }
            } else {
                if (!executeRecaptcha) {
                    setUserError(RecaptchaExceptionEnum.ExecuteRecaptchaNotYetAvailable);
                    return;
                }

                const verifyToken = await executeRecaptcha();
                setRecaptchaV2Flow(false);
                setUserError('');
                callback(props, `${verifyToken}`);
            }
        },
        [executeRecaptcha, callback, isRecaptchaV2Flow, recaptcha]
    );

    return [handler, RecaptchaV2Component];
};
