import { Form, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';

import { usePaymentToSendsForm } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.hook';
import { PaymentToSendsFormProps } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.interface';
import { PaymentToSendsFormValidation } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.validation';
import { PaymentToSendsStepForm } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-step-form/payment-to-sends-step-form';

export const PaymentToSendsForm = observer(
    ({ data, accounts, setModalTitle, selectedAccountId, clearPaymentFlow, isSignMode }: PaymentToSendsFormProps) => {
        const {
            activeStep,
            initialValues,
            isCreatePaymentLoading,
            isPaymentError,
            onBackBtnClick,
            onNextStep,
            onOtpSubmit,
            onPaymentSign,
            onPrevStep,
            onSendOtpCode,
            onSubmit,
            paymentUpdateStep,
            selectedAccount,
            setSignPaymentError,
            signPaymentError,
        } = usePaymentToSendsForm({
            accounts,
            clearPaymentFlow,
            data,
            isSignMode,
            selectedAccountId,
        });

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={activeStep === 0 ? onNextStep : onSubmit}
                validationSchema={PaymentToSendsFormValidation}
                validateOnChange={false}
                validateOnBlur={false}
                enableReinitialize
            >
                <Form>
                    <PaymentToSendsStepForm
                        accounts={accounts}
                        activeStep={activeStep}
                        isCreatePaymentLoading={isCreatePaymentLoading}
                        isPaymentError={isPaymentError}
                        isSignMode={isSignMode}
                        onBackBtnClick={onBackBtnClick}
                        onOtpSubmit={onOtpSubmit}
                        onPaymentSign={onPaymentSign}
                        onPrevStep={onPrevStep}
                        onSendOtpCode={onSendOtpCode}
                        paymentUpdateStep={paymentUpdateStep}
                        selectedAccount={selectedAccount}
                        setModalTitle={setModalTitle}
                        setSignPaymentError={setSignPaymentError}
                        signPaymentError={signPaymentError}
                    />
                </Form>
            </Formik>
        );
    }
);
