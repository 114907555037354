import { useLazyQuery } from '@apollo/client';
import { endOfDay, startOfDay } from 'date-fns';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { ModelContext } from '@shared-component/modal/modal.context';
import {
    GetPdfTransactionReceipt,
    GetPdfTransactions,
    GetReport,
    GetReportPresignedUrl,
    Query,
    ReportStatus,
} from '@shared-graphql';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { isTrue } from '@shared-util/is-data';

import { StatementFormInterface } from '@component/modal/modals/request-statement/statement-form/statement-form.interface';

export const useReportUrlGetting = (paymentId?: string) => {
    const { onClose } = useContext(ModelContext);
    const { onOpen } = useContext(InformationModalContext);

    const intervalId = useRef<number | null>(null);
    const globalWaitingTimerId = useRef<number | null>(null);
    const [isReportUrlLoading, setReportUrlLoading] = useState(false);
    const [url, setUrl] = useState('');

    const [getPDFTransactionReceipt, { data: getPDFTransactionReceiptData, error: getPDFTransactionReceiptError }] =
        useLazyQuery<Pick<Query, 'getPDFTransactionReceipt'>>(GetPdfTransactionReceipt);
    const [getPDFTransactions, { data: getPDFTransactionsData, error: getPDFTransactionsError }] =
        useLazyQuery<Pick<Query, 'getPDFTransactions'>>(GetPdfTransactions);
    const [getReport, { data: getReportData, error: getReportError }] =
        useLazyQuery<Pick<Query, 'getReport'>>(GetReport);
    const [getReportPresignedUrl, { data: getReportPresignedUrlData, error: getReportPresignedUrlError }] =
        useLazyQuery<Pick<Query, 'getReportPresignedUrl'>>(GetReportPresignedUrl);

    const finishProcessing = useCallback(() => {
        clearTimeout(globalWaitingTimerId.current as number);
        setReportUrlLoading(false);
        onClose();
    }, []);

    const reportId = useMemo(
        () =>
            isTrue(paymentId)
                ? getPDFTransactionReceiptData?.getPDFTransactionReceipt.reportId
                : getPDFTransactionsData?.getPDFTransactions.reportId,
        [paymentId, getPDFTransactionReceiptData, getPDFTransactionsData]
    );

    useEffect(() => {
        if (isTrue(url)) {
            window.location.href = url;
            finishProcessing();
        }
    }, [url]);

    useEffect(() => {
        if (isTrue(getReportPresignedUrlData?.getReportPresignedUrl.url)) {
            setUrl(getReportPresignedUrlData?.getReportPresignedUrl.url as string);
        }
    }, [getReportPresignedUrlData]);

    useEffect(() => {
        if (getReportData?.getReport.status === ReportStatus.done) {
            getReportPresignedUrl({ variables: { reportId } });
        }
        if (getReportData?.getReport.status === ReportStatus.failed) {
            finishProcessing();
            onOpen(InformationModalEnum.FailureInformationModal, {
                text: LocalizationEnum.FailureCurrencyExchangeMessage,
            });
        }
    }, [getReportData, reportId]);

    useEffect(() => {
        if (isTrue(reportId)) {
            intervalId.current = setInterval(() => getReport({ variables: { getReportId: reportId } }), 5000);
        }

        if ([ReportStatus.failed, ReportStatus.done].includes(getReportData?.getReport.status as ReportStatus)) {
            clearInterval(intervalId.current as number);
        }

        return () => clearInterval(intervalId.current as number);
    }, [reportId, getReportData]);

    useEffect(() => {
        if (getPDFTransactionsError || getReportError || getReportPresignedUrlError || getPDFTransactionReceiptError) {
            clearInterval(intervalId.current as number);
            finishProcessing();
            onOpen(InformationModalEnum.FailureInformationModal, {
                text:
                    getPDFTransactionReceiptError?.message ??
                    getReportPresignedUrlError?.message ??
                    getReportError?.message ??
                    getPDFTransactionsError?.message ??
                    LocalizationEnum.FailureCurrencyExchangeMessage,
            });
        }
    }, [getPDFTransactionsError, getReportError, getReportPresignedUrlError, getPDFTransactionReceiptError]);

    const handleSubmit = useCallback(
        ({ gte, lte, accountId }: StatementFormInterface) => {
            setReportUrlLoading(true);
            globalWaitingTimerId.current = setTimeout(() => {
                finishProcessing();
                onOpen(InformationModalEnum.FailureInformationModal, {
                    text: LocalizationEnum.SorrySomethingWentWrongPleaseTryAgainLater,
                });
            }, 60 * 1000);
            void (isTrue(paymentId)
                ? getPDFTransactionReceipt({ variables: { paymentId, accountId } })
                : getPDFTransactions({
                      variables: {
                          filters: {
                              accountId,
                              updatedAt: {
                                  gte: gte !== undefined ? startOfDay(gte) : null,
                                  lte: lte !== undefined ? endOfDay(lte) : null,
                              },
                          },
                      },
                  }));
        },
        [paymentId]
    );

    return { isReportUrlLoading, handleSubmit };
};
