export enum PaymentToSendsFormEnum {
    AccountId = 'accountId',
    Amount = 'amount',
    AttachedFiles = 'attachedFiles',
    Beneficiary = 'beneficiary',
    EmailOrIban = 'emailOrIban',
    CallbackUrl = 'callbackUrl',
    Currency = 'currency',
    DocumentIds = 'documentIds',
    Id = 'id',
    Purpose = 'purpose',
    Reason = 'reason',
    Type = 'type',
}
