import React from 'react';

import { ArrowLeft } from '@shared-atom/back-button/back-button.styles';
import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { LocalizationEnum } from '@shared-locale/localization.enum';

import { PaymentToSendsDetailsForm } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-details-form/payment-to-sends-details-form';
import { usePaymentToSendsStepForm } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-step-form/payment-to-sends-step-form.hook';
import { PaymentToSendsStepFormProps } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-step-form/payment-to-sends-step-form.interface';
import { PaymentUpdateSteps } from '@component/modal/modals/payment/payment-update-steps/payment-update-steps';
import { ButtonsWrapper, PaymentFormStepsWrapper } from '@component/modal/modals/payment/payment.modal-style';

export const PaymentToSendsStepForm = ({
    accounts,
    activeStep,
    isCreatePaymentLoading,
    isPaymentError,
    isSignMode,
    onBackBtnClick,
    onOtpSubmit,
    onPaymentSign,
    onPrevStep,
    onSendOtpCode,
    paymentUpdateStep,
    selectedAccount,
    setModalTitle,
    setSignPaymentError,
    signPaymentError,
}: PaymentToSendsStepFormProps) => {
    const {
        canCreate,
        fee,
        isFirstStepActive,
        isGetFeeLoading,
        isGetInternalBeneficiaryDetailsLoading,
        isLastStepActive,
        isNextBtnDisabled,
    } = usePaymentToSendsStepForm({ activeStep });

    return (
        <PaymentFormStepsWrapper>
            {isFirstStepActive && <PaymentToSendsDetailsForm fee={fee} accounts={accounts} />}
            {isLastStepActive && (
                <PaymentUpdateSteps
                    currency={selectedAccount?.currency.code as string}
                    fee={fee}
                    selectedAccount={selectedAccount}
                    signPaymentError={signPaymentError}
                    setSignPaymentError={setSignPaymentError}
                    step={paymentUpdateStep}
                    onPrevStep={onPrevStep}
                    onOtpSubmit={onOtpSubmit}
                    onPaymentSign={onPaymentSign}
                    isSignMode={isSignMode}
                    isLoading={isCreatePaymentLoading}
                    canSign={canCreate}
                    setModalTitle={setModalTitle}
                    ibanPaymentFlow
                    isPaymentError={isPaymentError}
                    onSendOtpCode={onSendOtpCode}
                />
            )}
            <ButtonsWrapper isDisable={isLastStepActive}>
                {isFirstStepActive && (
                    <>
                        <Button
                            onClick={onBackBtnClick}
                            type={ButtonTypeEnum.GHOST}
                            size={ButtonSizeEnum.L}
                            title={LocalizationEnum.CommonBack}
                            iconBefore={ArrowLeft}
                        />
                        <Button
                            type={ButtonTypeEnum.COLOR}
                            size={ButtonSizeEnum.L}
                            title={LocalizationEnum.CommonNext}
                            isSubmit
                            isDisabled={isNextBtnDisabled}
                            isLoading={
                                isGetInternalBeneficiaryDetailsLoading || isGetFeeLoading || isCreatePaymentLoading
                            }
                        />
                    </>
                )}
            </ButtonsWrapper>
        </PaymentFormStepsWrapper>
    );
};
