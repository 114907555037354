import { useMutation } from '@apollo/client';
import { FormikHelpers } from 'formik';
import { useCallback } from 'react';

import { JoinWaitingList, Mutation } from '@shared-graphql';
import { useGoogleRecaptcha } from '@shared-hook/google-recaptcha/google-recaptcha';

import { FooterFollowFormInterface } from '@page/main-pages/main-page-common/footer/footer-follow-form/footer-follow-form.interface';

type FollowFormStateType = FooterFollowFormInterface & Pick<FormikHelpers<FooterFollowFormInterface>, 'resetForm'>;

export const useFooterFollowForm = (): {
    onSubmit: ({ email, resetForm }: FollowFormStateType) => Promise<void>;
    isLoading: boolean;
} => {
    const [sendJoiningWaitingList, { loading: isLoading }] =
        useMutation<Pick<Mutation, 'joinWaitingList'>>(JoinWaitingList);

    const onFollow = useCallback(async ({ email, resetForm }: FollowFormStateType, verifyToken: string) => {
        await sendJoiningWaitingList({
            variables: {
                email,
                message: 'news',
                token: verifyToken,
            },
        });
        resetForm();
    }, []);

    const [onSubmit] = useGoogleRecaptcha(onFollow);

    return { onSubmit, isLoading };
};
