import { types } from 'mobx-state-tree';
import { $enum } from 'ts-enum-util';

import { AccountType, PaymentEntityType } from '../../graphql';
import { CurrencyModelType } from '../currency-model/currency-model.type';
import { AccountBankTypeSchema } from './account-bank-model/account-bank-model.type';

const typeValues = $enum(AccountType).getValues();
const entityValues = $enum(PaymentEntityType).getValues();

export const AccountCcTypeSchema = {
    id: types.identifier,
    accountId: types.string,
    balance: types.number,
    name: types.string,
    freezeBalance: types.maybeNull(types.number),
    realBalance: types.maybeNull(types.number),
    reservedBalance: types.maybeNull(types.number),
    currency: CurrencyModelType,
    type: types.enumeration(typeValues),
    legalEntityType: types.maybeNull(types.enumeration(entityValues)),
    banks: types.array(types.maybe(types.model(AccountBankTypeSchema))),
};
