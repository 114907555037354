import { CurrenciesEnum } from '@shared-enum/currencies.enum';
import { PaymentType } from '@shared-graphql';

import { PaymentToSendsFormEnum } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.enum';
import { PaymentToSendsFormInterface } from '@component/modal/modals/payment/payment-to-sends-form/payment-to-sends-form.interface';

export const PaymentToSendsFormInitial: PaymentToSendsFormInterface = {
    [PaymentToSendsFormEnum.AccountId]: '',
    [PaymentToSendsFormEnum.Amount]: 0,
    [PaymentToSendsFormEnum.Beneficiary]: null,
    [PaymentToSendsFormEnum.EmailOrIban]: '',
    [PaymentToSendsFormEnum.CallbackUrl]: null,
    [PaymentToSendsFormEnum.Currency]: CurrenciesEnum.GBP,
    [PaymentToSendsFormEnum.DocumentIds]: [],
    [PaymentToSendsFormEnum.Id]: null,
    [PaymentToSendsFormEnum.Purpose]: null,
    [PaymentToSendsFormEnum.Reason]: '',
    [PaymentToSendsFormEnum.Type]: PaymentType.internal,
};
