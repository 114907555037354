import { Form, Formik } from 'formik';
import { FC, ReactNode } from 'react';

import { Button } from '@shared-atom/elpaso-kit/button';
import { ButtonSizeEnum, ButtonTypeEnum } from '@shared-atom/elpaso-kit/button/types';
import { Translate } from '@shared-atom/translate/translate';
import { UserCredentialsStepForm as UserCredentialsStepFormFields } from '@shared-component/forms/user-credentials-step-form/user-credentials-step-form';
import { FormWrapper } from '@shared-form/form-common/form-common.styles';
import { UserCredentialsStepFormInterface } from '@shared-form/user-credentials-step-form/user-credentials-step-form.interface';
import { UserCredentialsStepFormSchema } from '@shared-form/user-credentials-step-form/user-credentials-step-form.validation';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';

import { GoogleRecaptchaInfo } from '@component/google-recaptcha-info/google-recaptcha-info';
import { initialsUserCredentialsStepForm } from '@page/auth-pages/login/login-steps/user-credentials-step/user-credentials-step-form/user-credentials-step-form.initials';
import { ForgotText } from '@page/auth-pages/login/login-steps/user-credentials-step/user-credentials-step-form/user-credentials-step.styles';

export interface UserCredentialsStepFormProps {
    onSubmit: OnEventType<UserCredentialsStepFormInterface>;
    error?: string;
    isLoading: boolean;
    setValueForgot?: any;
    recaptchaV2Component?: ReactNode | null;
}

export const UserCredentialsStepForm: FC<UserCredentialsStepFormProps> = ({
    onSubmit,
    isLoading,
    error,
    setValueForgot,
    recaptchaV2Component,
}) => (
    <Formik
        initialValues={initialsUserCredentialsStepForm}
        validationSchema={UserCredentialsStepFormSchema}
        validateOnBlur={false}
        validateOnChange={false}
        onSubmit={onSubmit}
    >
        <Form>
            <FormWrapper>
                <UserCredentialsStepFormFields error={error} />
                {recaptchaV2Component}
                <Button
                    title={LocalizationEnum.LoginFormTitle}
                    size={ButtonSizeEnum.L}
                    type={ButtonTypeEnum.COLOR}
                    isSubmit
                    isDisabled={isLoading}
                    isLoading={isLoading}
                    style={{ marginTop: '24px', width: '100%' }}
                />
                <ForgotText onClick={() => setValueForgot(true)}>
                    <Translate langKey={LocalizationEnum.ForgotPassword} />
                </ForgotText>
                <GoogleRecaptchaInfo />
            </FormWrapper>
        </Form>
    </Formik>
);
