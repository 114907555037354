import { useLazyQuery } from '@apollo/client';
import { GraphQLError } from 'graphql/index';
import { ReactNode, useCallback, useContext, useEffect, useRef, useState } from 'react';
import TagManager from 'react-gtm-module';

import { InformationModalContext } from '@shared-component/information-modal/information-modal.context';
import { InformationModalEnum } from '@shared-component/information-modal/information-modal.enum';
import { CheckUserExist, CheckUserExistAnswer, MutationSendRegistrationMailArgs, Query } from '@shared-graphql';
import { useGoogleRecaptcha } from '@shared-hook/google-recaptcha/google-recaptcha';
import { useLocalizationText } from '@shared-hook/localization/use-localization-text.hook';
import { useRouterNavigate } from '@shared-hook/navigation/use-current-navigation.hook';
import { LocalizationEnum } from '@shared-locale/localization.enum';
import { OnEventType } from '@shared-type/on-event.type';
import { getGraphQLErrorMessages } from '@shared-util/get-graphql-error-code';
import { isFalse, isString, isTrue } from '@shared-util/is-data';

import { GtmEventEnum } from '@enum/gtm-event.enum';
import { PageEnum } from '@enum/page.enum';
import { useUserActionsSelector } from '@selector/user/use-user.actions-selector';
import { getTagManagerEvent } from '@util/gtm.util';

export const useSendRegistrationMail = (): [
    OnEventType<MutationSendRegistrationMailArgs & { firstName: string; lastName: string }>,
    boolean,
    ReactNode | null
] => {
    const { onOpen } = useContext(InformationModalContext);
    const navigate = useRouterNavigate();
    const [
        checkUserExist,
        { data: checkUserExistResult, loading: isLoadingCheckUserExist, error: checkUserExistError },
    ] = useLazyQuery<Pick<Query, 'checkUserExist'>>(CheckUserExist);

    const emailRef = useRef<string>('');
    const [isEmailChecked, setEmailCheck] = useState(false);

    const { setUserData } = useUserActionsSelector();

    const checkUserExistAndReCaptchaVerifyHandler = useCallback(async (email: string, verifyToken: string) => {
        checkUserExist({ variables: { email, token: verifyToken } });
    }, []);
    const [checkUserExistAndReCaptchaVerify, recaptchaV2Component] = useGoogleRecaptcha(
        checkUserExistAndReCaptchaVerifyHandler
    );

    const handleSendMail = ({
        email,
        firstName,
        lastName,
    }: MutationSendRegistrationMailArgs & { firstName: string; lastName: string }) => {
        emailRef.current = email;
        checkUserExistAndReCaptchaVerify(email);
        setUserData({ email, firstName, lastName });
    };

    const userExistErrorText = useLocalizationText(LocalizationEnum.UserAlreadyExists);

    useEffect(() => {
        TagManager.dataLayer(
            getTagManagerEvent(isEmailChecked ? GtmEventEnum.EmailComplete : GtmEventEnum.EmailVerify)
        );
    }, [isEmailChecked]);

    useEffect(() => {
        const checkUserExistAnswer = (checkUserExistResult?.checkUserExist as CheckUserExistAnswer) ?? false;

        if (isTrue(checkUserExistAnswer)) {
            if (isFalse(checkUserExistAnswer?.isUserExist)) {
                const isEmailVerified = isString(emailRef.current);
                setEmailCheck(isEmailVerified);

                if (isEmailVerified) {
                    navigate(PageEnum.RegistrationAdditionalFields);
                }
            } else {
                onOpen(InformationModalEnum.FailureInformationModal, {
                    text:
                        getGraphQLErrorMessages(checkUserExistError?.graphQLErrors as GraphQLError[])[0] ??
                        checkUserExistError?.message ??
                        userExistErrorText,
                    timeout: 5000,
                });
            }
        }
    }, [checkUserExistResult, emailRef]);

    return [handleSendMail, isLoadingCheckUserExist, recaptchaV2Component];
};
