import styled from 'styled-components';

import { FormComponentWrapper, FormWrapper } from '@component/modal/modals/payment/payment.modal-style';

export const FormDetailsWrapper = styled(FormWrapper)`
    height: calc(100% - 84px);
`;

export const FormSectionWrapper = styled(FormComponentWrapper)`
    padding-bottom: 16px;
`;
